import { useMap } from 'react-leaflet';
import { useEffect } from 'react';
import L from 'leaflet';
import CustomControlButton from './CustomControlButton';

interface CustomControlProps {
  buttonText: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}


const CustomControl: React.FC<CustomControlProps> = ({ buttonText, onClick }) => {
  const map = useMap();

  useEffect(() => {
    const customControl = new CustomControlButton({ position: 'topright' });
    customControl.onAdd = (map) => {
      const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom jkpg-custom');
      const button = L.DomUtil.create('button', '', container);

      button.innerHTML = buttonText;
      button.type = 'button';
      button.onclick = (event) => onClick(event as unknown as React.MouseEvent<HTMLButtonElement>);

      return container;
    };
    customControl.addTo(map);

    return () => {
      customControl.remove();
    };
  }, [map, buttonText, onClick]);

  return null;
};

export default CustomControl;