import L from 'leaflet';

class CustomControlButton extends L.Control {
  constructor(options: any) {
    super(options);
  }

  onAdd(map: any) {
    const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
    const button = L.DomUtil.create('button', '', container);

    button.innerHTML = 'My Button';
    button.type = 'button';
    button.onclick = () => {
      // Add the desired functionality for the button here
      console.log('Button clicked!');
    };

    return container;
  }
}

export default CustomControlButton;