export const Locations = [
  {	
      'name': "Hannas kök",
      'address': "Ödeshögsvägen 36, 563 32 Gränna",
      'coordinates': "58.0326145,14.4795819",
      'categories': 'café',     
      'subject': 'Mat runt Gränna',         
      'description': 'Njut av läcker fika i trädgårdscaféet eller välkomponerade rätter i restaurangen. För större sällskap finns det möjlighet att boka för en egen kväll. Hannas kök erbjuder även catering för små och stora evenemang året runt!',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/59/61/59619559-2e39-49db-826e-c7d1b19a7669/hannas_kok.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/granna-visingso/hannas-kok/?edit_off',
      'mapLink': 'https://www.google.com/maps/place/Hannas+K%C3%B6k/@58.0326145,14.4795819,17z/data=!3m1!4b1!4m6!3m5!1s0x465a38e8ac101f93:0xf3e81a46b8f9f7dc!8m2!3d58.0326145!4d14.4795819!16s%2Fg%2F11h0vtz5mv'                
  },
  {
      'name': 'Grännaknäcke',
      'address': 'Brahegatan 43, 563 32 Gränna',
      'coordinates': '58.0223587,14.4672714',
      'categories': 'butik',     
      'subject': 'Mat runt Gränna',         
      'description': 'I den mysiga butiken mitt i Gränna hittar du egentillverkat knäckebröd, biscotti och skorpor tillsammans med andra närproducerade produkter. Titta in i bageriet och se bagaren tillverka våra knäckebröd eller boka en tid och prova själv. ',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/22/e5/22e53556-d9cd-4cba-900f-79ec691a37c6/grannaknacke_1440x900.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/granna-visingso/granna-knacke/',
      'mapLink': 'https://www.google.com/maps/place/Gr%C3%A4nna+Kn%C3%A4cke/@58.0223587,14.4672714,17z/data=!3m1!4b1!4m6!3m5!1s0x465a39d0cd2625bd:0xd75be39d2db7b8c0!8m2!3d58.0223587!4d14.4672714!16s%2Fg%2F11h6m94mx8 '        
  },
  {
      'name': 'Grenna Glass',
      'address': 'Brahegatan 41, 563 32 Gränna',
      'coordinates': '58.022625,14.4671393',
      'categories': 'butik',     
      'subject': 'Mat runt Gränna',         
      'description': 'Hantverksmässig glass utan tillsatser och färgämnen. Tillverkad i den lilla glassfabriken mitt i centrala Gränna med närproducerade ingredienser.',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/e8/0b/e80b29e3-10ee-446d-b610-f0644f61575d/glass_1440x900.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/granna-visingso/grenna-glass/',
      'mapLink': 'https://www.google.com/maps/place/Grenna+Glass/@58.022625,14.4671393,17z/data=!3m1!4b1!4m6!3m5!1s0x465a38c40a5eb919:0xee74e1caecf46a2a!8m2!3d58.022625!4d14.4671393!16s%2Fg%2F11cnb1p9c1 '        
  }, 
{
      'name': 'Grenna Steakhouse',
      'address': 'Amiralsvägen 69, 563 31 Gränna',
      'coordinates': '58.0284877,14.4549328',
      'categories': 'café',
      'subject': 'Mat runt Gränna',
      'description': 'Restaurangen befinner sig på populära Gränna Hamn och har en fantastisk panorama vy över Vättern. Här kan du avnjut smakrika maträtter tillsammans med färgglada cocktails',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/c4/e4/c4e45211-121b-450d-bb93-1c89e90149e6/steakhouse.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/granna-visingso/grenna-steakhouse/',
      'mapLink': 'https://www.google.com/maps/place/Grenna+Steakhouse/@58.0284877,14.4532105,17z/data=!3m1!4b1!4m6!3m5!1s0x465a39635ad75bf1:0xc61af8ccd32c1930!8m2!3d58.0284877!4d14.4557854!16s%2Fg%2F11t0rfqtt1?entry=ttu'
  },
  {
      'name': 'Grenna polkagriskokeri',
      'address': 'Brahegatan 39, 563 32 Gränna',
      'coordinates': '58.022346,14.464225',
      'categories': 'butik',        
      'subject': 'Mat runt Gränna',      
      'description': 'Prova Grännas rödvita signaturgodis i ett av de större polkagriskokeriet i staden. I butiken finns polkagrisar, karameller, klubbor, kola och knäck så långt ögat kan nå. Du kan även se hur bagarna gör polkagrisar på samma sätt som Amalia Eriksson gjorde på 1800-talet.',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/54/c4/54c4fa63-7f59-456f-beed-4ba363ff4ff8/grenna-polkagriskokeri-exterior.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/granna-visingso/grenna-polkagriskokeri/',
      'mapLink': 'https://www.google.com/maps/place/Grenna+Polkagriskokeri+AB/@58.0223461,14.4646114,17z/data=!4m12!1m5!3m4!2zNTjCsDAxJzIwLjYiTiAxNMKwMjcnNTIuNSJF!8m2!3d58.0223906!4d14.4645839!3m5!1s0x465a38c40c10c663:0x8b7aa73fa1bbc131!8m2!3d58.022346!4d14.4667999!16s%2Fg%2F1hc39h5wq'        
  },  
{	
      'name': "Hamnkrogen",
      'address': "Amiralsvägen 52, 563 31 Gränna",
      'coordinates': "58.0296741,14.4560097",
      'categories': 'café',        
      'subject': 'Mat runt Gränna',      
      'description': 'God mat i trevlig miljö i Gränna Hamn. Hamnkrogen kan också kallas för Nöjeskrogen i Gränna då det är en hel del event och artister som uppträder där. Här följer man trender med mat men har även klassiker som laxfilé på menyn.',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/7d/20/7d20d822-c892-43ee-a420-75680832293c/macka_salla_webb.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/granna-visingso/hamnkrogen-i-granna/',
      'mapLink': 'https://www.google.com/maps/place/Hamnkrogen/@58.0296741,13.3050201,9z/data=!4m10!1m2!2m1!1sHamnkrogen!3m6!1s0x465a3895583cadfd:0xc2618291124b6dcf!8m2!3d58.0296741!4d14.4585846!15sCgpIYW1ua3JvZ2VuWgwiCmhhbW5rcm9nZW6SAQpyZXN0YXVyYW504AEA!16s%2Fg%2F1hc5zy82k?entry=ttu'        
    },
{	
      'name': "Gyllene Uttern",
      'address': "Gyllene Uttern 1, 563 92 Gränna",
      'coordinates': "57.9963023,14.4383185",
      'categories': 'boende',     
      'subject': 'Mat runt Gränna',         
      'description': 'Välkommen till hotellet med en av Sveriges vackraste utsikter! Upplev en vacker och rofylld miljö med ett personligt boende och utsökta måltider. Restaurangen ger en känsla av tradition med maträtter komponerade efter säsong med fantastisk utsikt över Vättern.',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/71/89/7189a802-9538-4161-a23f-57d3c8badc5f/gylleneuttern-main_picture1440.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/granna-visingso/hotel-gyllene-uttern/',
      'mapLink': 'https://www.google.com/maps/place/Hotel+Gyllene+Uttern/@57.9963023,14.4383185,17z/data=!3m1!4b1!4m9!3m8!1s0x46453e14c4803ee3:0x6b17cbb5b357510b!5m2!4m1!1i2!8m2!3d57.9962995!4d14.4405072!16s%2Fg%2F11dyl5131 '        
    },
    {
      'name': "Ravelsmarks gård",
      'address': "Ravelmarks gård, 563 92 Gränna",
      'coordinates': "57.9973182,14.4489635",
      'categories': 'gårdsbutik',    
      'subject': 'Mat runt Gränna',          
      'description': 'Välkommen till ett idylliskt besök på landet hos Ravelsmarks Gård. I gårdsbutiken finns bland annat jordgubbar, majs, vitkål och sparris från egen odling tillsammans med andra lokala varor. Under sommaren är även caféet öppet. ',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/a3/ca/a3ca3801-711b-4825-9cb0-3e0d5e521cf5/ravelsmarks_gardsbutik.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/granna-visingso/ravelsmarks-gard/',
      'mapLink': 'https://www.google.com/maps/place/Ravelsmarks+g%C3%A5rdsbutik+och+caf%C3%A9/@57.9973182,14.4489635,17z/data=!4m12!1m5!3m4!2zNTfCsDU5JzUwLjQiTiAxNMKwMjYnNTYuMyJF!8m2!3d57.9973182!4d14.4489635!3m5!1s0x465a3f30ccfb1ee7:0x37bf954a7d6c660d!8m2!3d57.9973182!4d14.4511575!16s%2Fg%2F11bv1s61jj '        
    },
{	
      'name': "Eriks Bröd & Kombucha",
      'address': "Åldermansvägen 1, Örserum",
      'coordinates': "58.0071375,14.5784992",
      'categories': 'butik',    
      'subject': 'Mat runt Gränna',
      'description': 'Besök detta härliga mikrobageri i Örserum! Här finns fantastiska bullar och matbröd samt egenbrygd kombucha. Om du kommer förbi bjuder Erik självklart på kaffe och te vid ditt besök. ',
      'image': 'https:\/\/matruntgranna.se\/wp-content\/uploads\/2023\/04\/Namnlos-design-45-1-300x251.png',
      'link': 'https://jkpg.com/sv/granna/eriks-brod-och-kombucha/',
      'mapLink': 'https://www.google.com/maps/place/%C3%85ldermansv%C3%A4gen+1,+563+91+Gr%C3%A4nna/@58.0071375,14.5784992,17z/data=!3m1!4b1!4m6!3m5!1s0x465a3bef0fa33755:0xaa8ee0fd2397a0cd!8m2!3d58.0071347!4d14.5810741!16s%2Fg%2F11sbc3lpnb '        
    }, 
{	
      'name': "Smålandsgården",
      'address': "Gamla brunnsvägen 8, 563 91 Örserum, Gränna",
      'coordinates': "58.006471,14.5746913",
      'categories': 'boende, restaurang',     
      'subject': 'Mat runt Gränna',         
      'description': 'Smålandsgården arbetar med hållbarhet i fokus och erbjuder boendealternativ i idyllisk småländsk miljö. Restaurangen erbjuder rustik mat med inspiration från Italien och sydstaterna, med närproducerade svenska råvaror.',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/33/31/33318c90-499a-4d85-8bf2-0d3db38b8d0d/smalandsgarden.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/granna-visingso/smalandsgarden/',
      'mapLink': 'https://www.google.com/maps/place/Sm%C3%A5landsg%C3%A5rden/@58.006471,14.5746913,17z/data=!3m1!4b1!4m9!3m8!1s0x465a3be50ee5a011:0x53c253d0c9145fd9!5m2!4m1!1i2!8m2!3d58.006471!4d14.5746913!16s%2Fg%2F11j0bnsjr4'        
    },   
    {
      'name': "Lupiners gård",
      'address': "Södra Boarp 4, 563 92 Gränna",
      'coordinates': "57.9555395,14.4816853",
      'categories': 'gårdsbutik',    
      'subject': 'Mat runt Gränna',          
      'description': 'Lupiners Gård föder upp lamm av rasen Gotlandsfår. I gårdsbutiken Lupiners Magasin finns ett stort urval av skinn från gårdens djur och kött från gårdens djur. I butiken hittar du även gårdens honung och annat som är producerat i närområdet.',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/c3/58/c3584907-5732-4b00-8440-ed4750af05d6/lupiners-gard-_dsc4426.jpeg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/granna-visingso/lupiners-gard/',
      'mapLink': 'https://www.google.com/maps/place/Lupiners+G%C3%A5rd/@57.9555395,14.4816853,17z/data=!3m1!4b1!4m6!3m5!1s0x465a3f6e3f83f1ef:0x24d395013ddaf360!8m2!3d57.9555395!4d14.4816853!16s%2Fg%2F11j90yhwlj '        
    },  
{	
      'name': "Flättinge gårdscafé",
      'address': "Uppegård 4, 561 91 Huskvarna",
      'coordinates': "57.9154611,14.3741804",
      'categories': 'café',     
      'subject': 'Mat runt Gränna',         
      'description': 'Flättinge gårdscafé erbjuder hembakad fika och lättare luncher som är tillagade från grunden utifrån säsongens råvaror. Njut av godsakerna i det ombyggda stallet eller på uteserveringen i en gammal beteshage.',
      'image': 'https:\/\/matruntgranna.se\/wp-content\/uploads\/2022\/04\/Flattinge_190627_FOTOGRAFEMMAIVARSSON-38-300x200.jpg',
      'link': 'https://jkpg.com/sv/jonkoping-huskvarna/flattinge-gardscafe/',
      'mapLink': 'https://www.google.com/maps/place/Fl%C3%A4ttinge+G%C3%A5rdscaf%C3%A9/@57.9154611,14.3741804,17z/data=!3m1!4b1!4m6!3m5!1s0x465a1550ec647747:0x3fd45cc93f647c73!8m2!3d57.9154611!4d14.3741804!16s%2Fg%2F11c3mrq3db'        
    }, 
{	
      'name': "Bauergården",
      'address': "Bunn, 563 93 Gränna",
      'coordinates': "57.936114,14.4915181",
      'categories': 'boende, restaurang',   
      'subject': 'Mat runt Gränna',           
      'description': 'Njut av den härliga Bauermenyn som har skapats av lokala råvaror med inspiration från de trolska skogarna i Bunn. Vill du inte njuta av maten i restaurangen kan du prova att tillaga maten själv över öppen eld på en utav grillplatserna. Här finns även boende i hotellrum och stuga samt ställplats för husbilar',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/f5/1e/f51ef0a2-5a0d-4b22-a70f-96689a0266cd/webbbauergaerden_23.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/granna-visingso/bauergarden/',
      'mapLink': 'https://www.google.com/maps/place/BauerG%C3%A5rden/@57.936114,14.4915181,17z/data=!3m1!4b1!4m9!3m8!1s0x465a3e6c648cb02f:0x6a220c581e8b5ca3!5m2!4m1!1i2!8m2!3d57.936114!4d14.4915181!16s%2Fg%2F11bwfjcjc3'        
    },  
    {
      'name': "Bosons Gård",
      'address': "Åsa 5, 563 92 Åsa",
      'coordinates': "58.0067861,14.5998833",
      'categories': 'gårdsbutik',    
      'subject': 'Mat runt Gränna',          
      'description': 'Bosons gård föder upp en av världens äldsta koraser, Chianina. I butiken finns styckdetaljer, hamburgare och olika sorters korvar till försäljning.',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/32/5b/325bb719-4c37-4f08-a43f-5376df0ada8e/2009-11-17_043.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/granna-visingso/bosons-gard/',
      'mapLink': 'https://www.google.com/maps/place/Bosons+G%C3%A5rd/@58.0067861,14.5998833,17z/data=!4m12!1m5!3m4!2zNTjCsDAwJzI0LjQiTiAxNMKwMzYnMDcuNSJF!8m2!3d58.0067833!4d14.602072!3m5!1s0x465a3b80c1118ce7:0xdaf3066a606c06c7!8m2!3d58.0062677!4d14.603727!16s%2Fg%2F11jj6vv6_8 '
    }, 
{	
      'name': "Adelövs Gård",
      'address': "Adelövs gård, 573 98 Tranås",
      'coordinates': "58.0312357,14.6674298",
      'categories': 'gårdsbutik',       
      'subject': 'Mat runt Gränna',       
      'description': 'I gårdsbutiken på Adelövs Gård hittar du ekologisk kyckling och nötkött som föds upp på gården, men även andra närproducerade produkter från området.',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/07/e4/07e4b546-ab80-49e5-b049-33fd93a9e015/393e136a-69f2-4c12-9f4c-1ab174c62cf7.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/ovriga-omraden/adelovs-gard/',
      'mapLink': 'https://www.google.com/maps/place/Adel%C3%B6vs+Lantbruks+AB/@58.0312357,14.6674298,17z/data=!3m1!4b1!4m6!3m5!1s0x465a3ae31cd94f53:0x74bf01b1d97d8e8!8m2!3d58.0312329!4d14.6700047!16s%2Fg%2F1hc5230cc '        
    },  
  {
      'name': 'Sjöarps Rökeri',
      'address': 'Sjöarp 1, 573 97 Tranås',
      'coordinates': '58.0076857,14.7682857',
      'categories': 'butik',     
      'subject': 'Mat runt Gränna',         
      'description': 'Sjöarps Rökeri är ett familjeföretag som erbjuder rökta delikatesser av både kött och fisk, som röks över öppen eld med alved på ett hantverksmässigt sätt. ',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/13/c8/13c85b90-5028-4494-8bc0-74fc3ae102bf/sjoarps-martin.jpeg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/ovriga-omraden/sjoarps-rokeri/',
      'mapLink': 'https://www.google.com/maps/place/Sj%C3%B6arps+R%C3%B6keri/@58.0076857,14.7682857,17z/data=!3m1!4b1!4m6!3m5!1s0x465988218295a67b:0xacb7712226d346b2!8m2!3d58.0076857!4d14.7682857!16s%2Fg%2F1hc96ctj1 '        
  },  
{	
      'name': "Stalpets Café",
      'address': "Stalpet, 578 94 Aneby",
      'coordinates': "57.8495407,14.8236846",
      'categories': 'café',        
      'subject': 'Mat runt Gränna',      
      'description': 'Slå dig ner i trädgården och njut av hembakad fika och blicka ut över södra Sveriges största vattenfall, Stalpet. Under sommarmånaderna anordnas även olika aktiviteter och uppträdande. Glöm inte att kika in i hantverksboden när du är där!',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/c7/48/c7488129-2eb2-4de7-9c35-3c6b48f83210/namnlas_design-55_1.png__1200x800_q85_crop_subsampling-2.png',
      'link': 'https://jkpg.com/sv/ovriga-omraden/stalpets-cafe/',
      'mapLink': 'https://www.google.com/maps/place/Stalpets+caf%C3%A9,+minigolf+och+hantverk/@57.8495407,14.8236846,17z/data=!3m1!4b1!4m6!3m5!1s0x465a21d2e9b00df9:0xe3a80854a573f346!8m2!3d57.8495407!4d14.8236846!16s%2Fg%2F11d_wgwvz8 '        
    },
    {
      'name': "Prästgårdens mejeri",
      'address': "Lommaryds Prästgård 2, 578 91",
      'coordinates': "57.8885342,14.7509032",
      'categories': 'gårdsbutik',    
      'subject': 'Mat runt Gränna',          
      'description': 'I Prästgårdens mejeri förädlas den goda mjölken från deras egna SRB-kor till bland annat ostkaka och yoghurt. I gårdsbutiken kan du få ta del av alla gårdens mejeriprodukter såväl som andra lokalproducerade delikatesser. Förutom det finns där även fårskinn och skinnprodukter från granngården Gynge samt hantverk. ',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/39/c5/39c5ec48-ac0d-40f7-87e8-11dcad682804/135713470_976997082826997_4655115865856615325_n.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/ovriga-omraden/prastgardens-mejeri/',
      'mapLink': 'https://www.google.com/maps/place/Pr%C3%A4stg%C3%A5rdens+Mejeri/@57.8885342,14.7509032,17z/data=!3m1!4b1!4m6!3m5!1s0x465a23c2de52a7ed:0x31ed960742d874d3!8m2!3d57.8885342!4d14.7509032!16s%2Fg%2F11t6hn44_t '        
    },   
  {
      'name': 'Gröna Lund i Vireda',
      'address': 'Vireda Grönalund 1, 578 92 Aneby',
      'coordinates': '57.9096008,14.6339943',
      'categories': 'café',   
      'subject': 'Mat runt Gränna',           
      'description': 'Njut av hembakad fika i den vackra sekelskiftsvillans café eller ta en tur in till butiken för att fynda både nytt, gammalt och egentillverkat.',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/c5/e1/c5e13c86-412a-4cd3-b906-9a9d32257058/grona_lund_vireda.png__1200x800_q85_crop_subsampling-2.png',
      'link': 'https://jkpg.com/sv/ovriga-omraden/grona-lund-i-vireda/',
      'mapLink': 'https://www.google.com/maps/place/Gr%C3%B6na+Lund+i+Vireda/@57.9096008,14.6339943,17z/data=!3m1!4b1!4m6!3m5!1s0x465a3dc30e89cf3d:0x7578e6a6ab974ac8!8m2!3d57.9096008!4d14.6339943!16s%2Fg%2F11hz6nhdqs'        
  },
  {
      'name': 'Kulturreservatet Åsens by',
      'address': 'Haurida, 578 92 Aneby',
      'coordinates': '57.8948768,14.5185719',
      'categories': 'butik',        
      'subject': 'Mat runt Gränna',      
      'description': 'Upplev hur människorna levde på den småländska landsbygden runt år 1900. Se jordbruk med hästkraft, brödbak och stortvätt i brygghuset. Ta en bit mat som anpassas efter årstider och närodlade råvaror eller stanna en natt. ',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/69/d4/69d47fcb-5549-459e-a7d6-f702e4b0db21/221e0235-4bd.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/ovriga-omraden/asens-by/',
      'mapLink': 'https://www.google.com/maps/place/%C3%85sens+by/@57.8948768,14.5185719,17z/data=!3m1!4b1!4m9!3m8!1s0x465a1610826045bb:0xb09e61d858f44e3c!5m2!4m1!1i2!8m2!3d57.8948768!4d14.5185719!16s%2Fg%2F11b6696rlv '        
  },
  {
      'name': 'Grafitgrå café',
      'address': 'Vistakullevägen 1, 561 46 Huskvarna',
      'coordinates': '57.85877147393391,14.308717548069236',
      'categories': 'café',    
      'subject': 'Mat runt Gränna',          
      'description': 'I Kaxholmens gamla smedja intill Landsjön och Kaxholmens badplats finns café, inredning och modebutik under ett tak. Njut av hembakade bakverk och flatbread med olika smakkombinationer i caféet. Under sommaren är även glasskiosken öppen. Med vackra promenadstråk och leksplatser i närheten är det en härlig upplevelse för hela familjen. ',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/1f/b2/1fb224fd-fa29-42fd-afe2-ebbacb968114/cookies.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/jonkoping-huskvarna/grafitgra-cafe/ ',
      'mapLink': 'https://www.google.com/maps/place/Grafitgr%C3%A5/@57.8587358,14.3065354,17z/data=!4m12!1m5!3m4!2zNTfCsDUxJzMxLjUiTiAxNMKwMTgnMjMuNSJF!8m2!3d57.8587358!4d14.3065354!3m5!1s0x465a6b46424bdbe9:0x5ba02ed373de756a!8m2!3d57.858718!4d14.308783!16s%2Fg%2F1yh9vh6rq '        
  },
    {
      'name': "Gisebo vin",
      'address': "Ed 20, 561 92 Huskvarna",
      'coordinates': "57.8455592,14.2946472",
      'categories': 'gårdsbutik',     
      'subject': 'Mat runt Gränna',         
      'description': 'I Gisebodalen odlas druvor som sedan används för att framställa vin på ett manuellt och hantverksmässigt sätt. För större sällskap går det även att boka vinprovning med mat. ',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/c6/2e/c62ef588-05e5-4bb5-ae1d-eaba70f052ac/gisebo-vin-appledalen-1024x536.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/jonkoping-huskvarna/gisebo-vin/',
      'mapLink': 'https://www.google.com/maps/place/ED+20,+561+92+Huskvarna/@57.8455592,14.2946472,17z/data=!3m1!4b1!4m6!3m5!1s0x465a6b5a2c7d23a1:0x613e45f781352c52!8m2!3d57.8455592!4d14.2946472!16s%2Fg%2F11c43z563k '        
    }, 
{	
      'name': "Strands gård",
      'address': "Strands gård, Gisebo 1, Huskvarna",
      'coordinates': "57.8337428,14.269579",
      'categories': 'gårdsbutik',        
      'subject': 'Mat runt Gränna',      
      'description': 'I gårdsbutiken på Strands gård säljs egenproducerad mjölk, yoghurt, grädde och ägg året om. Här finns även grönsaker, rotfrukter och blommor från lokala grannproducenter efter säsong. Missa inte att komma hit under sensommaren för den årliga majsplockningen!',
      'image': 'https://static.wixstatic.com/media/9214d5_456645b82d2a44bfb82e56654b852a0d~mv2.jpg/v1/crop/x_0,y_1,w_1024,h_487/fill/w_980,h_466,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PHOTO-2021-03-30-17-55-51%209.jpg',
      'link': 'https://jkpg.com/sv/jonkoping-huskvarna/strands-gard-gardsbutik/',
      'mapLink': 'https://www.google.com/maps/place/Strands+G%C3%A5rd+-+G%C3%A5rdsbutik/@57.8337428,14.269579,17z/data=!3m1!4b1!4m6!3m5!1s0x465a6d634c9742bd:0x4ca10d8b2cd0d84!8m2!3d57.83374!4d14.2721539!16s%2Fg%2F11q86hd78n '        
    }, 
    {
      'name': "Rudenstams bär & frukt",
      'address': "Rudu gård 50, 561 92 Huskvarna",
      'coordinates': "57.8452428,14.2803467",
      'categories': 'gårdsbutik',  
      'subject': 'Mat runt Gränna',            
      'description': 'Mitt bland äppelodlingar och en fantastisk utsikt finns Rudenstams gårdsbutik och café. Odlingarna varierar efter säsong med äpple, päron, bigarråer och jordgubbar. I gårdsbutiken säljs förutom frukt även egenproducerad dryck och andra närproducerade produkter. Slå dig ner och ta en fika, njut av härliga delikatesser och lunch i caféet. ',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/bc/6e/bc6ec6cf-bd08-4ae2-8941-698ea67492bf/rudenstam_01.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/jonkoping-huskvarna/rudenstams-gardsbutik-kafe/',
      'mapLink': 'https://www.google.com/maps/place/Rudenstams+G%C3%A5rdsbutik+%26+Kaf%C3%A9/@57.8452428,14.2803467,17z/data=!3m1!4b1!4m6!3m5!1s0x465a6b60a5726d6b:0x82c43cb9a7614cc4!8m2!3d57.8452428!4d14.2803467!16s%2Fg%2F1yh9vgb68 '        
    },   
  {
      'name': "Vistakulle Fruktodling",
      'address': "Bosgård Norrgård 1, 561 92 Huskvarna",
      'coordinates': "57.8595923,14.2806687",
      'categories': 'gårdsbutik',  
      'subject': 'Mat runt Gränna',            
      'description': 'Vistakullens fruktodling ligger i Östra vätterbranternas biosfärområde med utsikt över Vättern. I gårdsbutiken säljs äpplen, päron och plommon, inklusive andra egenförädlade produkter samt andra närproducerade varor. Under sommaren har du möjlighet att njuta av dessa varor på gårdens café. ',
      'image': 'https://jkpg.com/media/filer_public_thumbnails/filer_public/74/02/7402fde3-3f93-4ef0-8682-7ef2d30cccd0/appleodling.jpg__1200x800_q85_crop_subsampling-2.jpg',
      'link': 'https://jkpg.com/sv/jonkoping-huskvarna/vistakulle-fruktodling/',
      'mapLink': 'https://www.google.com/maps/place/Vistakulle+Fruktodling+och+G%C3%A5rdsbutik/@57.8595923,14.2806687,16.97z/data=!4m12!1m5!3m4!2zNTfCsDUxJzM3LjciTiAxNMKwMTYnNDUuNyJF!8m2!3d57.8604688!4d14.2793617!3m5!1s0x465a6d294728da91:0x5db32eeb7647a41f!8m2!3d57.8604314!4d14.2815355!16s%2Fg%2F11b7rnfyt0'        
    },                                                            
];