import React, { useEffect, useCallback, useRef, createRef   } from 'react';
import { BrowserRouter as Router, Route, Link, useLocation } from 'react-router-dom';
import { MapContainer, TileLayer, useMap, Marker, Popup, LayerGroup, Polygon, useMapEvents } from 'react-leaflet';
import './App.css';
import biosfarsomrade from './biosfarsomrade.json';
import kommunyta from './kommunyta.json';
import CustomMarker from './CustomMarker';
import InvertedPolygon from './InvertedPolygon';

import L, { LatLng } from 'leaflet';
import { LatLngTuple, Map, LatLngExpression, LatLngLiteral  } from 'leaflet';
import { useState } from 'react';
import { Locations } from './Locations';
import CustomButton from './CustomButton';

const largeRectangle: LatLngExpression[] = [
  [-90, -180],
  [-90, 180],
  [90, 180],
  [90, -180],
];

const biosfarsomrade_feature = biosfarsomrade.features.find((f) => f.geometry.type === 'Polygon')

const kommunyta_feature_polygonCoordinates = kommunyta.features.map((feature) => {
  return feature.geometry.coordinates[0].map((coord: any) => L.latLng(coord[1], coord[0])) as LatLngExpression[];
});

const biosfarsomrade_feature_polygonCoordinates = biosfarsomrade_feature
  ? biosfarsomrade_feature.geometry.coordinates[0].map((coord) => L.latLng(coord[1], coord[0])) as LatLngExpression[]
  : [];  


const generateMarkers = (center: [number, number]) => {
  const markers = [];

  for (let i = 0; i < Locations.length; i++) {
    let location = Locations[i];

    var lat = center[0] + (Math.random() - 0.5) * 0.2; // 0.2 degrees is about 22 km
    var lng = center[1] + (Math.random() - 0.5) * 0.2;

    if (location.coordinates != '') {
      var coordinates = location.coordinates.split(',');
      lat = parseFloat(coordinates[0]);
      lng = parseFloat(coordinates[1]);
    }


    var iconUrl = '../icons/övrigt.svg';

    if (location.categories.indexOf('butik') > -1) {
      iconUrl = '../icons/butik.svg';
    }
    else if (location.categories.indexOf('gårdsbutik') > -1) {
      iconUrl = '../icons/gårdsbutik.svg';
    }
    else if (location.categories.indexOf('aktivitet') > -1) {
      iconUrl = '../icons/aktiviteter.svg';
    }

    else if (location.categories.indexOf('café') > -1) {
      iconUrl = '../icons/cafe.svg';
    }
    else if (location.categories.indexOf('boende') > -1) {
      iconUrl = '../icons/boende_.svg';
    }
    else if (location.categories.indexOf('restaurang') > -1) {
      iconUrl = '../icons/restaurang.svg';
    }    

    var useStamp = false;
    if(location.subject.indexOf('Mat runt Gränna') > -1) {
      useStamp = true;
    }


    const name = location.name;
    const description = location.description;
    const address = location.address;
    const category = location.categories;
    const link = location.link;
    const mapLink = location.mapLink;
    var image = location.image;

    if (image == '') {
      image = "popupfeature.png";
    }

    markers.push({ name, lat, lng, iconUrl, description, address, category, image, mapLink, link, useStamp });
  }

  return markers;
};

const params = new URLSearchParams(window.location.search);

const allMarkers = generateMarkers([58.0345, 14.4596]); // Gränna



type MarkerType = {
  name: string;
  lat: number;
  lng: number;
  iconUrl: string;
};

interface MapEventsProps {
  updateMarkers: (map: Map) => void;
}
var initDone = false;
function App() {
  const [markers, setMarkers] = useState<MarkerType[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<MarkerType>();
  const [showBioLayer, setShowBioLayer] = useState<boolean>(true);
  const [isMarkerSelectedManually, setIsMarkerSelectedManually] = useState(false);
  const [leafletMap, setLeafletMap] = useState<Map | null>(null); 
  const [isShowInfoActive, setIsShowInfoActive] = useState(false);  


  const latitude = 58.0345;
  const longitude = 14.4596;
  const position: LatLngTuple = [latitude, longitude];// Gränna  
  const markerRefs = useRef(allMarkers.map(() => createRef<HTMLLIElement>()));
  const ulRef = useRef<HTMLUListElement>(null);  


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const showBio = params.get("showBio");
    setShowBioLayer(showBio === null ? false : showBio === "true");
  
    const markerName = params.get("marker");
    if (markerName) {
      const selectedMarker = allMarkers.find(marker => marker.name === markerName);
      if (selectedMarker) {
        setSelectedMarker(selectedMarker);
      }
    }
  }, []);

  const updateURLWithMarker = (marker: MarkerType) => {
    const params = new URLSearchParams(window.location.search);
    params.set("marker", marker.name);
    window.history.replaceState({}, "", `${window.location.pathname}?${params.toString()}`);
  };

  const MapClickHandler = () => {
    const map = useMapEvents({
      click: (e) => {
        const target = e.originalEvent.target;
        if (!(target instanceof L.Marker)) {
          setSelectedMarker(undefined);
          clearMarkerFromURL();
          //
        }
      },
    });

    return null;
  };  


  const clearMarkerFromURL = () => {
    setTimeout(() => {
      map.invalidateSize(); 
    }, 100);

    const params = new URLSearchParams(window.location.search);
    params.delete("marker");

    const menu = document.querySelector('.activity-menu');
    menu!.classList.remove('showInfo');  
    setIsShowInfoActive(false); // Add this line    
    window.history.replaceState({}, "", `${window.location.pathname}?${params.toString()}`);
  };  

  const handleMarkerClick = (marker: any, map: any) => {

    // If the clicked marker is already selected, unselect it
    if (selectedMarker && selectedMarker.name === marker.name) {
      setSelectedMarker(undefined);
      const menu = document.querySelector('.activity-menu');
      menu!.classList.remove('showInfo');  
      clearMarkerFromURL();
    } else {
      // Otherwise, select the clicked marker
      setSelectedMarker(marker);
      setIsMarkerSelectedManually(true);
      const { lat, lng } = marker;
  
      // Function to handle map actions
      const handleMapActions = () => {
        map.invalidateSize(); 
        map.flyTo([lat, lng], map.getZoom());
      };
        map.eachLayer((layer: any) => {
          if (layer instanceof L.Marker && layer.getLatLng().equals([lat, lng])) {
            layer.openPopup();
          }
        });
    
        const menu = document.querySelector('.activity-menu');
        setIsShowInfoActive(true); // Add this line      
        menu!.classList.add('showInfo');    
    
        updateURLWithMarker(marker);

  
      // Detect whether the screen width is 768px or lower
      const isMobile = window.innerWidth <= 768;
  
      // If the screen width is 768px or lower, delay the call to handleMapActions
      // Otherwise, call it immediately
      if (isMobile) {
        setTimeout(handleMapActions, 100);
      } else {
        handleMapActions();
      }
    }
  };

  const updateMarkers = useCallback((map: Map) => {
    const bounds = map.getBounds();
    const newMarkers = allMarkers.filter((marker: MarkerType) =>
      bounds.contains([marker.lat, marker.lng])
    );


    setMarkers(newMarkers);
  }, [allMarkers]);


  var map: any;
  const MapUpdate: React.FC<MapEventsProps> = ({ updateMarkers }) => {
    map = useMap();

    useEffect(() => {
      if (initDone === false) {
        updateMarkers(map);
        setLeafletMap(map);
        initDone = true;
    
        if (selectedMarker) {
          handleMarkerClick(selectedMarker, map);
        }
      }
    
      const onMoveEnd = () => updateMarkers(map);
      map.on('moveend', onMoveEnd);
    
      // Cleanup function to remove the event listener when the component unmounts
      return () => {
        map.off('moveend', onMoveEnd);
      };
    }, [map, updateMarkers, selectedMarker]);

    return null;
  };


  useEffect(() => {
    if (selectedMarker && !isMarkerSelectedManually) {
      const markerExists = markers.some(marker => marker.name === selectedMarker.name);
      if (markerExists) {
        handleMarkerClick(selectedMarker, map);
      }
    }
  }, [markers, selectedMarker, map, isMarkerSelectedManually]);

  useEffect(() => {
    if (map) {
      map.on('popupclose', () => {
        setIsMarkerSelectedManually(false);
        setSelectedMarker(undefined);

        const menu = document.querySelector('.activity-menu');
        menu!.classList.remove('showInfo');        

        clearMarkerFromURL();
      });
    }
    return () => {
      if (map) {
        map.off('popupclose');
      }
    };
  }, [map]);  

  useEffect(() => {
    if (selectedMarker) {
      const isMobile = window.innerWidth <= 768;
  
      // Otherwise, call it immediately
      if (isMobile) {
        setTimeout(() => {
          const index = allMarkers.findIndex(marker => marker.name === selectedMarker.name);
          if (markerRefs.current[index] && markerRefs.current[index].current) {
            const listItem = markerRefs.current[index].current;

            if (ulRef.current && listItem) {
              /*listItem.scrollIntoView({ behavior: 'smooth', block: 'start' });*/
              listItem.scrollIntoView({block: "start", inline: "nearest"});
              document.getElementsByClassName("activity-menu")[0]!.scrollTop -= 100;
            }
          }
        }, 1); // 1000 milliseconds = 1 second
      }
    }
  }, [selectedMarker]);

  const toggleBioLayer = (event: React.MouseEvent<HTMLButtonElement>) => {
    const newShowBioLayer = !showBioLayer;
    setShowBioLayer(newShowBioLayer);
    updateURL(newShowBioLayer);

    event.stopPropagation();
  };  

  const updateURL = (showBioLayer: boolean) => {
    const params = new URLSearchParams(window.location.search);
    params.set("showBio", showBioLayer.toString());
    window.history.replaceState({}, "", `${window.location.pathname}?${params.toString()}`);
  };  

  function toggleMenu(onlyClose: boolean = false) {        
    var menu = document.querySelector('.activity-menu');

    if (menu!.classList.contains('showInfo')) {
      menu!.classList.remove('showInfo');
      menu!.classList.remove('collapsed');
      setIsShowInfoActive(false);
    }
    else {    
      if(onlyClose == false) {
        menu!.classList.toggle('collapsed');
      }
      else if(onlyClose === true && menu!.classList.contains('collapsed')) {
        menu!.classList.toggle('collapsed');
      }
    }

    if (leafletMap) {
      leafletMap.invalidateSize();

      setTimeout(() => {
        leafletMap.invalidateSize();
      }, 200);  // adjust the delay as needed

      setTimeout(() => {
        leafletMap.invalidateSize();
      }, 1000);  // adjust the delay as needed      
    }    
  }


  return (
    <div>
    <div style={{ display: 'flex', height: '100vh' }}>
      <div className="activity-menu" >
        <div>

      <button className="activity-menu-toggle" onClick={() => toggleMenu(false)}></button>
        <h2>DELTAGARE</h2>
        <ul style={{ flexGrow: 1, overflow: 'auto'}} className="activities-list" ref={ulRef}>
          {allMarkers.map((marker, index) => (
            <li
            key={marker.name}
            ref={markerRefs.current[index]} // Lägg till detta
            className={`marker-icon ${selectedMarker && selectedMarker.name === marker.name ? "expandInfo" : ""}`}
            onClick={() => { 
              handleMarkerClick(marker, map); 
              toggleMenu(true);
            }}>

                
              <div style={{
                backgroundImage: `url(${marker.image ? marker.image : 'placeholder.jpg'})`
              }} className={`activity-thumbnail ${marker.useStamp ? "mrgStamp" : ""}`}>
              </div>

              <div className="activity-content">
                <p>
                  {marker.name}
                </p>

                <div className='activity-content-details'>
                  <p>
                    {marker.description}
                    <br /> <br />
                    <a href={marker.link}>Besök hemsidan</a>
                  </p>

                  <p>
                    <a href={marker.mapLink} target="_blank">{marker.address} (visa på karta)</a>
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        </div>
      </div>


<div style={{ flex: '1', height: '100vh', width: '100%' }} className={`map-container ${isShowInfoActive ? 'showInfo' : ''}`}>
      <MapContainer center={position} zoom={10} style={{ flex: '1', height: '100vh', width: '100%' }} >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {allMarkers.map((marker, index) => (
          <CustomMarker
            key={marker.name}
            position={[marker.lat, marker.lng]}
            iconUrl={marker.iconUrl}            
            onClick={() => handleMarkerClick(marker, leafletMap)}
            selected={selectedMarker?.name === marker.name} 
          >
            <Popup>
              <div className={marker.useStamp ? "mrgStamp" : ""}>
                <img className={"popupImage"} src={marker.image} />

                <h2>
                  {marker.name}
                </h2>

                <p>
                  {marker.description}
                  <br /> <br />
                  <a href={marker.link} target="_blank">Besök hemsidan</a>
                </p>

                <p>
                  <a href={marker.mapLink} target="_blank">{marker.address} (visa på karta)</a>
                </p>
              </div>
            </Popup>
          </CustomMarker>
        ))}

        <LayerGroup>

          <InvertedPolygon
            outerPolygon={largeRectangle}
            innerPolygons={kommunyta_feature_polygonCoordinates}
          />

        </LayerGroup>


        {showBioLayer && (
          <LayerGroup key={showBioLayer.toString()}>
              <Polygon color="gray" className='bio' positions={biosfarsomrade_feature_polygonCoordinates} />
          </LayerGroup>
        )}

        <CustomButton buttonText={showBioLayer ? "Dölj Vätterbranterna Biosfärområde" : "Visa Vätterbranterna Biosfärområde"} onClick={toggleBioLayer} />

        <MapUpdate updateMarkers={updateMarkers} />
        <MapClickHandler />
      </MapContainer>
      </div>
    </div>
    </div>
  );
}

export default App;