import { Polygon } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';

interface InvertedPolygonProps {
    outerPolygon: LatLngExpression[];
    innerPolygons: LatLngExpression[][];
  }
  
  const InvertedPolygon: React.FC<InvertedPolygonProps> = ({ outerPolygon, innerPolygons }) => {
    const holeStructure = [outerPolygon, ...innerPolygons];
  
    return <Polygon  positions={holeStructure} />;
  };
  
  export default InvertedPolygon;