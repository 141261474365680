import React, { useEffect, useState } from 'react';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';

interface CustomMarkerProps {
  position: LatLngExpression;
  iconUrl: string;
  onClick: () => void;
  children: React.ReactNode;
  selected: boolean; // Add this prop to indicate if the marker is selected
}

const CustomMarker: React.FC<CustomMarkerProps> = ({ position, iconUrl, onClick, children, selected }) => {
  const markerRef = React.useRef<L.Marker>(null);
  const [svgContent, setSvgContent] = useState<string | null>(null);

  useEffect(() => {
    fetch(iconUrl)
      .then((response) => response.text())
      .then((data) => setSvgContent(data));
  }, [iconUrl]);

  useEffect(() => {
    if (markerRef.current && svgContent) {
      const icon = L.divIcon({
        className: `custom-icon ${selected ? 'selected' : ''}`, // Add 'selected' class if the marker is selected
        iconSize: [40, 56], // Adjust this to the size of your icon
        iconAnchor: [12.5, 41], // Adjust this based on the size and the desired anchor point
        popupAnchor: [0, -41], // Adjust this based on the size and the desired popup anchor point
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        shadowSize: [51, 51], // Adjust this to the size of your shadow icon
        shadowAnchor: [12.5, 45] ,// Adjust this based on the size and the desired shadow anchor point    
        html: `<div style="position: relative;">
        <div style="z-index: 2;">${svgContent}</div>
        <div class="marker-shadow" style="position: absolute; top: 0; left: 6; z-index: 1;"></div>
      </div>`
      });
      markerRef.current.setIcon(icon);
    }
  }, [svgContent, selected]); // Rerun this effect if the selected status changes


  return (
    <Marker ref={markerRef} position={position} eventHandlers={{ click: onClick }}>
      {children}
    </Marker>
  );
};

export default CustomMarker;